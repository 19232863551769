<template>
  <div>
    <!-- <div class="vs-component vs-con-table stripe vs-table-primary">
      <header class="header-table vs-table--header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="
              p-3
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button
            size="small"
            color="success"
            type="border"
            @click="handleExport('excel')"
            >Excel</vs-button
          >
        </div>
        <div class="con-input-search vs-table--search">
          <input type="text" class="input-search vs-table--search-input" /><i
            class="vs-icon notranslate icon-scale material-icons null"
            >search</i
          >
        </div>
      </header>
      <ag-grid-vue
        style="height: 100%"
        ref="agGridTable"
        :gridOptions="agGrid.options"
        class="ag-theme-material ag-grid-table"
        :columnDefs="agGrid.columnDefs"
        :defaultColDef="agGrid.defaultColDef"
        :rowData="agGrid.data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :onGridReady="onGridReady"
      ></ag-grid-vue>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div> -->
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="btn-group ml-5">
            <vs-button
              size="small"
              color="success"
              type="border"
              @click="handleExport('excel')"
              >Excel</vs-button
            >
          </div>
        </template>
        <template slot="thead">
          <!-- <vs-th></vs-th> -->
          <vs-th
            v-for="(head, index) in this.table.heads"
            :key="index"
            :sort-key="head.field"
            >{{ head.title }}</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td> </vs-td> -->
            <vs-td
              v-for="(head, index) in table.heads"
              :key="index"
              :data="data[indextr][head.field]"
              >{{ format(head, table.data[indextr][head.field]) }}</vs-td
            >
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>
<script>
// import { AgGridVue } from "ag-grid-vue";
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import moment from "moment";
export default {
  components: {
    // AgGridVue,
  },
  props: {
    baseUrl: {
      type: String
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    },
    territoryIDs: {
      type: Array
    },
    territoryNames: {
      type: Array
    },
    draw: {
      type: Number
    }
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState()
      // agGrid: {
      //   api: null,
      //   data: [],
      //   options: {},
      //   columnDefs: [
      //     {
      //       headerName: "Total",
      //       field: "total_target",
      //       width: 125,
      //       // pinned: "left",
      //       // valueFormatter: this.formatPrice,
      //     },
      //   ],
      //   defaultColDef: {
      //     resizable: true,
      //   },
      // },
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          {
            title: "Cust Payment No",
            field: "code"
          },
          {
            title: "Remark",
            field: "remark"
          },
          {
            title: "Description",
            field: "invoice_reference_code"
          },
          {
            title: "Doc Status",
            field: "status"
          },
          {
            title: "Sales Office Code",
            field: "territory_code"
          },
          {
            title: "Document Date",
            field: "invoice_payment_date"
          },
          {
            title: "Posting Date",
            field: "payment_posting_date"
          },
          {
            title: "Bill Ref Code",
            field: "invoice_code"
          },
          {
            title: "Customer ID",
            field: "customer_code"
          },
          {
            title: "Customer Name",
            field: "customer_name"
          },
          {
            title: "Bill Amt",
            field: "invoice_total",
            format: this.formatPrice
          },
          {
            title: "Total Payment",
            field: "payment_amount",
            format: this.formatPrice
          },
          {
            title: "Reversed Amount",
            field: "reversed_amount",
            format: this.formatPrice
          },
          {
            title: "Remain Bill Amt",
            field: "remain_bill_amt",
            format: this.formatPrice
          },
          {
            title: "Bill Round Amt",
            field: "bill_round_amt",
            format: this.formatPrice
          }
        ]
      };
    },
    // onGridReady(params) {
    //   this.agGrid.api = params.api;
    //   this.agGrid.api.setDomLayout("autoHeight");
    // },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/table", {
            params: {
              length: this.table.length,
              page: this.table.page,
              search: this.table.search,
              order: this.table.order,
              sort: this.table.sort,
              status: this.status,
              territory_ids: this.territoryIDs,
              start_date: this.startDate
                ? moment(this.startDate).format("YYYY-MM-DD")
                : null,
              end_date: this.endDate
                ? moment(this.endDate).format("YYYY-MM-DD")
                : null
            }
          })
          .then(resp => {
            if (resp.status == "success") {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "ARPAYMENT_REPORT_" +
        (this.territoryNames.length > 0
          ? this.territoryNames.join("-")
          : "all");

      if (this.startDate || this.endDate) {
        fileTitle +=
          "_POSTING_DATE_" +
          moment(this.startDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDate).format("YYYY-MM-DD");
      }

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            territory_ids: this.territoryIDs,
            start_date: this.startDate
              ? moment(this.startDate).format("YYYY-MM-DD")
              : null,
            end_date: this.endDate
              ? moment(this.endDate).format("YYYY-MM-DD")
              : null,
            file: file,
            title: fileTitle
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        });
    },
    handleExport2(file) {
      this.$vs.loading();
      var fileTitle =
        "PAYMENT_REPORT_" +
        "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");

      this.$http
        .get(this.baseUrl + "/export", {
          params: {
            territory_id: this.territoryID,
            start_date: moment(this.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.endDate).format("YYYY-MM-DD"),
            file: file
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream"
          }
        })
        .then(resp => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            if (file == "excel") {
              fileLink.setAttribute("download", fileTitle + ".xlsx");
            } else {
              fileLink.setAttribute("download", fileTitle + ".pdf");
            }
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    // detail() {
    //   this.getData();
    // },
    // startDate() {
    //   this.getData();
    // },
    // endDate() {
    //   this.getData();
    // },
    // territoryID() {
    //   this.getData();
    // },
    draw() {
      console.log("draw");
      this.getData();
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  }
};
</script>